const closeDisclaimer = () => {
    const disclaimer = document.querySelector(".disclaimer");
    const disclaimerButton = document.querySelector(".disclaimer-button");
    const cookieDisclaimer = localStorage.getItem("cookies_disclaimer");

    if (!cookieDisclaimer || cookieDisclaimer === null || cookieDisclaimer === undefined) {
    disclaimerButton.addEventListener("click", () => {
    disclaimer.style.display = 'none';
    localStorage.setItem("cookies_disclaimer", true)
    });
    } else {
        disclaimer.style.display = 'none';
    }
}

export { closeDisclaimer } ;
