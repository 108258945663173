import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const textAnimation = () => {
    gsap.registerPlugin(ScrollTrigger);

    const textElements = gsap.utils.toArray('.text-animation');

    textElements.forEach(text => {
    gsap.to(text, {
        backgroundSize: '100%',
        ease: 'none',
        scrollTrigger: {
        trigger: text,
        start: 'center 80%',
        end: 'center 20%',
        scrub: true,
        },
    });
    });
}

export { textAnimation } ;